<script setup lang="ts">
import errorImage from '~/assets/img/error.svg';

useHead({
  title: 'BlazingFast',
})

function logError(error: any) {
  if (import.meta.env.DEV) throw error;
}
</script>

<template>
  <NuxtRouteAnnouncer />
  <NuxtLayout>
    <NuxtErrorBoundary @error="logError">
      <NuxtPage />
      <template #error="{ clearError }">
        <div class="w-full h-full flex flex-col justify-center items-center gap-4 text-center">
          <img :src="errorImage" alt="Error 500" class="w-96" />
          <p>
            Something went wrong with your last operation.
          </p>
          <p>
            The error has been recorded and our team is actively working to resolve it.
          </p>
          <p>
            Please try again in a few hours.
          </p>
          <NuxtButton size="lg" icon="i-lucide-refresh-cw" @click="clearError">
            Refresh page
          </NuxtButton>
        </div>
      </template>
    </NuxtErrorBoundary>
  </NuxtLayout>
  <NuxtNotifications />
  <BasePaidPlanOnlyModal />
  <BaseQuotaExceededModal />
</template>

<style>
#__nuxt {
  width: 100vw;
  height: 100vh;
}

.page-enter-active,
.page-leave-active {
  transition: all 0.25s ease;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>
