import { default as indexaZuxFU64wsMeta } from "/var/www/blazingfast/pages/agents/[id]/index.vue?macro=true";
import { default as preview3E0LIZR9CVMeta } from "/var/www/blazingfast/pages/agents/[id]/preview.vue?macro=true";
import { default as indexLPAMpHaBg2Meta } from "/var/www/blazingfast/pages/agents/index.vue?macro=true";
import { default as _91id_93zWoeUAF8gbMeta } from "/var/www/blazingfast/pages/ai-flows/[id].vue?macro=true";
import { default as indexwNVuFkKYQMMeta } from "/var/www/blazingfast/pages/ai-flows/index.vue?macro=true";
import { default as confirmNqvJw51TsZMeta } from "/var/www/blazingfast/pages/auth/confirm.vue?macro=true";
import { default as forgot_45passwordKjdIIFjocxMeta } from "/var/www/blazingfast/pages/auth/forgot-password.vue?macro=true";
import { default as invitation9yvqMqSWx4Meta } from "/var/www/blazingfast/pages/auth/invitation.vue?macro=true";
import { default as login3qDH9NpgmrMeta } from "/var/www/blazingfast/pages/auth/login.vue?macro=true";
import { default as signupn6Gd57QEBYMeta } from "/var/www/blazingfast/pages/auth/signup.vue?macro=true";
import { default as update_45passwordMkYOr7bOZpMeta } from "/var/www/blazingfast/pages/auth/update-password.vue?macro=true";
import { default as indexIg6RyFSbgqMeta } from "/var/www/blazingfast/pages/contacts/index.vue?macro=true";
import { default as _91id_93ecj0k6Daa2Meta } from "/var/www/blazingfast/pages/custom-objects/[id].vue?macro=true";
import { default as indexN8aSSZRaOnMeta } from "/var/www/blazingfast/pages/custom-objects/index.vue?macro=true";
import { default as _91id_93AXb0aBpH0iMeta } from "/var/www/blazingfast/pages/inbox/[id].vue?macro=true";
import { default as indexITbbGwb4A9Meta } from "/var/www/blazingfast/pages/inbox/index.vue?macro=true";
import { default as indexxecVVbiUSIMeta } from "/var/www/blazingfast/pages/index.vue?macro=true";
import { default as indexBaAIQI2b1BMeta } from "/var/www/blazingfast/pages/reports/index.vue?macro=true";
import { default as billingfzT1NoiCtJMeta } from "/var/www/blazingfast/pages/settings/billing.vue?macro=true";
import { default as contactjsV3Uw949zMeta } from "/var/www/blazingfast/pages/settings/contact.vue?macro=true";
import { default as _91id_93uXmXTEIOeDMeta } from "/var/www/blazingfast/pages/settings/custom-object/[id].vue?macro=true";
import { default as indexq8HWTWTDFKMeta } from "/var/www/blazingfast/pages/settings/custom-object/index.vue?macro=true";
import { default as profileM8NRPd0ffOMeta } from "/var/www/blazingfast/pages/settings/profile.vue?macro=true";
import { default as tag7Tjsxjadu3Meta } from "/var/www/blazingfast/pages/settings/tag.vue?macro=true";
import { default as usersqvIpumzEEUMeta } from "/var/www/blazingfast/pages/settings/users.vue?macro=true";
import { default as whatsappcAbq1ZpOnMMeta } from "/var/www/blazingfast/pages/settings/whatsapp.vue?macro=true";
import { default as workspaceHA3kY4zIEUMeta } from "/var/www/blazingfast/pages/settings/workspace.vue?macro=true";
import { default as payment4BhUg6DlaOMeta } from "/var/www/blazingfast/pages/subscriptions/payment.vue?macro=true";
import { default as plansihfzzmwYFNMeta } from "/var/www/blazingfast/pages/subscriptions/plans.vue?macro=true";
import { default as _91id_93ZUVIVAKBBLMeta } from "/var/www/blazingfast/pages/whatsapp/templates/[id].vue?macro=true";
import { default as createmKxXRCBdYaMeta } from "/var/www/blazingfast/pages/whatsapp/templates/create.vue?macro=true";
import { default as indexZcAX0BhC2NMeta } from "/var/www/blazingfast/pages/whatsapp/templates/index.vue?macro=true";
import { default as _91id_93S0Z1mj0VFBMeta } from "/var/www/blazingfast/pages/workflows/[id].vue?macro=true";
import { default as indexSwrYlkhEUmMeta } from "/var/www/blazingfast/pages/workflows/index.vue?macro=true";
export default [
  {
    name: "agents-id",
    path: "/agents/:id()",
    component: () => import("/var/www/blazingfast/pages/agents/[id]/index.vue")
  },
  {
    name: "agents-id-preview",
    path: "/agents/:id()/preview",
    component: () => import("/var/www/blazingfast/pages/agents/[id]/preview.vue")
  },
  {
    name: "agents",
    path: "/agents",
    component: () => import("/var/www/blazingfast/pages/agents/index.vue")
  },
  {
    name: "ai-flows-id",
    path: "/ai-flows/:id()",
    component: () => import("/var/www/blazingfast/pages/ai-flows/[id].vue")
  },
  {
    name: "ai-flows",
    path: "/ai-flows",
    component: () => import("/var/www/blazingfast/pages/ai-flows/index.vue")
  },
  {
    name: "auth-confirm",
    path: "/auth/confirm",
    meta: confirmNqvJw51TsZMeta || {},
    component: () => import("/var/www/blazingfast/pages/auth/confirm.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordKjdIIFjocxMeta || {},
    component: () => import("/var/www/blazingfast/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-invitation",
    path: "/auth/invitation",
    meta: invitation9yvqMqSWx4Meta || {},
    component: () => import("/var/www/blazingfast/pages/auth/invitation.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: login3qDH9NpgmrMeta || {},
    component: () => import("/var/www/blazingfast/pages/auth/login.vue")
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: signupn6Gd57QEBYMeta || {},
    component: () => import("/var/www/blazingfast/pages/auth/signup.vue")
  },
  {
    name: "auth-update-password",
    path: "/auth/update-password",
    meta: update_45passwordMkYOr7bOZpMeta || {},
    component: () => import("/var/www/blazingfast/pages/auth/update-password.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/var/www/blazingfast/pages/contacts/index.vue")
  },
  {
    name: "custom-objects-id",
    path: "/custom-objects/:id()",
    component: () => import("/var/www/blazingfast/pages/custom-objects/[id].vue")
  },
  {
    name: "custom-objects",
    path: "/custom-objects",
    component: () => import("/var/www/blazingfast/pages/custom-objects/index.vue")
  },
  {
    name: "inbox-id",
    path: "/inbox/:id()",
    meta: _91id_93AXb0aBpH0iMeta || {},
    component: () => import("/var/www/blazingfast/pages/inbox/[id].vue")
  },
  {
    name: "inbox",
    path: "/inbox",
    meta: indexITbbGwb4A9Meta || {},
    component: () => import("/var/www/blazingfast/pages/inbox/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/blazingfast/pages/index.vue")
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/var/www/blazingfast/pages/reports/index.vue")
  },
  {
    name: "settings-billing",
    path: "/settings/billing",
    component: () => import("/var/www/blazingfast/pages/settings/billing.vue")
  },
  {
    name: "settings-contact",
    path: "/settings/contact",
    component: () => import("/var/www/blazingfast/pages/settings/contact.vue")
  },
  {
    name: "settings-custom-object-id",
    path: "/settings/custom-object/:id()",
    component: () => import("/var/www/blazingfast/pages/settings/custom-object/[id].vue")
  },
  {
    name: "settings-custom-object",
    path: "/settings/custom-object",
    component: () => import("/var/www/blazingfast/pages/settings/custom-object/index.vue")
  },
  {
    name: "settings-profile",
    path: "/settings/profile",
    component: () => import("/var/www/blazingfast/pages/settings/profile.vue")
  },
  {
    name: "settings-tag",
    path: "/settings/tag",
    component: () => import("/var/www/blazingfast/pages/settings/tag.vue")
  },
  {
    name: "settings-users",
    path: "/settings/users",
    component: () => import("/var/www/blazingfast/pages/settings/users.vue")
  },
  {
    name: "settings-whatsapp",
    path: "/settings/whatsapp",
    component: () => import("/var/www/blazingfast/pages/settings/whatsapp.vue")
  },
  {
    name: "settings-workspace",
    path: "/settings/workspace",
    component: () => import("/var/www/blazingfast/pages/settings/workspace.vue")
  },
  {
    name: "subscriptions-payment",
    path: "/subscriptions/payment",
    meta: payment4BhUg6DlaOMeta || {},
    component: () => import("/var/www/blazingfast/pages/subscriptions/payment.vue")
  },
  {
    name: "subscriptions-plans",
    path: "/subscriptions/plans",
    meta: plansihfzzmwYFNMeta || {},
    component: () => import("/var/www/blazingfast/pages/subscriptions/plans.vue")
  },
  {
    name: "whatsapp-templates-id",
    path: "/whatsapp/templates/:id()",
    component: () => import("/var/www/blazingfast/pages/whatsapp/templates/[id].vue")
  },
  {
    name: "whatsapp-templates-create",
    path: "/whatsapp/templates/create",
    component: () => import("/var/www/blazingfast/pages/whatsapp/templates/create.vue")
  },
  {
    name: "whatsapp-templates",
    path: "/whatsapp/templates",
    component: () => import("/var/www/blazingfast/pages/whatsapp/templates/index.vue")
  },
  {
    name: "workflows-id",
    path: "/workflows/:id()",
    meta: _91id_93S0Z1mj0VFBMeta || {},
    component: () => import("/var/www/blazingfast/pages/workflows/[id].vue")
  },
  {
    name: "workflows",
    path: "/workflows",
    component: () => import("/var/www/blazingfast/pages/workflows/index.vue")
  }
]