import type { QuotaType } from "~/stores/quota";

export const useModalStore = defineStore('modal', {
    state: () => {
        return {
            isOpen: false,
            deleteConfirmationModal: {
                isOpen: false,
                id: null as string | null,
                type: null as string | null,
                name: null as string | null,
            },
            quotaExceededModal: {
                isOpen: false,
                type: '' as QuotaType,
                isDailyLimit: false,
            },
            paidPlanOnlyModal: {
                isOpen: false,
                type: '',
            },
            templateReorderModal: false,
        }
    },

    actions: {
        open(id: string, type: string, name: string | null = null) {
            this.deleteConfirmationModal = {
                isOpen: true,
                id,
                type,
                name,
            }
        }
    }
})
