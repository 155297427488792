export default defineAppConfig({
	ui: {
		avatar: {
			background: 'bg-gray-300/65',
		},
		primary: 'blue',
		button: {
			default: {
				loadingIcon: 'i-lucide-loader',
			},
			color: {
				white: {
					solid: 'disabled:bg-gray-200/75',
				}
			},
			icon: {
				size: {
					sm: 'w-4 h-4',
					md: 'w-4 h-4',
				}
			},
		},
		checkbox: {
			label: 'text-gray-500 font-normal',
			inner: 'ms-2',
		},
		dropdown: {
			item: {
				icon: {
					base: 'w-4 h-4',
				}
			}
		},
		modal: {
			container: 'items-center',
		},
		table: {
			default: {
				sortButton: {
					ui: {
						base: 'w-max max-w-44',
					}
				}
			}
		},
		toggle: {
			default: {
				onIcon: 'i-lucide-check',
				offIcon: 'i-lucide-x',
			}
		}
	},
});
  