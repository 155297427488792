import * as Sentry from '@sentry/vue'
import { supabaseIntegration } from '@supabase/sentry-js-integration'

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: {
      appDomain,
      sentry,
      supabase,
    }
  } = useRuntimeConfig()

  if (!sentry.dsn || import.meta.env.DEV) {
    return
  }

  const router = useRouter()
  const supabaseClient = useSupabaseClient();

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      supabaseIntegration(supabaseClient, Sentry, {
        tracing: true,
        breadcrumbs: true,
        errors: true,
      }),
      Sentry.browserTracingIntegration({
        router,
        shouldCreateSpanForRequest: (url) => {
          return !url.startsWith(`${supabase.url}/rest`)
        },
      }), 
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
      Sentry.replayIntegration(),
    ],
    // Performance monitoring 
    tracesSampleRate: 0.2,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [`https://${appDomain}`],
    // Session replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.5,
  })
})