export default defineNuxtPlugin(() => {
    const nuxtToast = useToast();

    const closeButton: any = {
        ui: {
            base: 'absolute top-1 right-2 p-2',
        }
    }

    const toast = {
        success: (message: string) => nuxtToast.add({
            color: 'green',
            icon: 'i-lucide-circle-check',
            title: 'Success',
            description: message,
            closeButton,
        }),
        error: (message: string) => nuxtToast.add({
            color: 'red',
            icon: 'i-lucide-circle-x',
            title: 'Error',
            description: message,
            closeButton,
        }),
        info: (message: string) => nuxtToast.add({
            color: 'blue',
            icon: 'i-lucide-info',
            title: 'Info',
            description: message,
            closeButton,
        }),
    }

    return {
        provide: {
            toast,
        }
    }
})