import mitt from 'mitt'

type Events = {
    'save-before-exit': null;
    'add-content': {
        content: string;
        isHTML: boolean;
    };
} 

export default defineNuxtPlugin(() => {
    const emitter  = mitt<Events>();
    return {
        provide: {
            emitter: emitter.emit,
            listen: emitter.on,
        }
    }
});