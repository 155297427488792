import { useUserStore } from '~/stores/user';

export const usePosthogStore = defineStore('posthog', () => {
    const userStore = useUserStore();

    function params(excludeProfile: boolean = false) {
        const sharedProperties = {
            workspace_id: userStore.user?.workspaceId,
        }
        if (excludeProfile) {
            return sharedProperties;
        }
        return {
            auth_user_id: userStore.user?.id,
            user_id: userStore.user?.uid,
            email: userStore.user?.email,
            name: userStore.user?.displayName,
            ...sharedProperties,
        }
    }

    function identifyUser() {
        const { $posthog } = useNuxtApp();
        $posthog()?.identify(userStore.user?.uid, params())
    }

    function track(name: string, properties: Record<string, any> = {}) {
        const { $posthog } = useNuxtApp();
        $posthog()?.capture(name, {
            $set: {
                ...(params()),
            },
            ...(params(true)),
            ...properties,
        });
    }

    function reset() {
        const { $posthog } = useNuxtApp();
        $posthog()?.reset();
        track("User logged out")
    }

    return {
        identifyUser,
        track,
        reset,
    }
});
