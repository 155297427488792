export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.client) return;
  const user = useSupabaseUser();

  // console.log('Auth middleware user:', user.value?.id, {
  //   to: to.path,
  //   from: from.path,
  // });

  const publicPaths = [
    '/auth/login',
    '/auth/signup',
    '/auth/confirm',
    '/auth/invitation',
    '/auth/forgot-password',
    '/auth/update-password',
  ]

  const notPublicPath = !publicPaths.includes(to.path);

  if (!user.value && notPublicPath) {
    console.log('Auth middleware redirecting to login');
    return navigateTo('/auth/login'); 
  }

  if (!user.value?.app_metadata?.workspace_id && notPublicPath) {
    console.log('Auth middleware redirecting to onboarding');
    return navigateTo('/auth/confirm');
  }

  if (user.value?.app_metadata?.workspace_id && !notPublicPath && to.path !== '/auth/invitation') {
    console.log('User authenticated. Auth middleware redirecting to index page');
    return navigateTo('/inbox');
  }

  if (user.value?.app_metadata?.role === "agent" && !["/inbox", "/settings/profile"].includes(to.path)) {
    console.log("Unauthorized access by agent user detected. Redirecting to inbox")
    return navigateTo("/inbox")
  }
})
