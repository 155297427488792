<script setup lang="ts">
import { useModalStore } from "~/stores/modal";
import startCase from 'lodash/startCase';

const modalStore = useModalStore();

const reset = () => {
  modalStore.paidPlanOnlyModal.isOpen = false;
  modalStore.paidPlanOnlyModal.type = '';
};
</script>

<template>
  <BaseModal
    :is-open="modalStore?.paidPlanOnlyModal?.isOpen"
    @close="reset"
    size="sm"
  >
    <div class="sm:flex sm:items-start">
      <div
        class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
      >
        <NuxtIcon
          name="i-heroicons-exclamation-triangle"
          class="h-6 w-6 text-red-600"
        />
      </div>
      <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
        <h3
          class="text-base font-semibold leading-6 text-gray-900"
          id="modal-title"
        >
          {{ startCase(modalStore?.paidPlanOnlyModal?.type ?? 'this feature') }} is only available to paid plan users
        </h3>
        <div class="mt-2">
          <p class="text-sm text-gray-600 tracking-wide">
            To unlock this feature, please upgrade to a paid plan.
          </p>
        </div>
      </div>
    </div>
    <template #footer-action>
      <NuxtLink to="/subscriptions/plans" target="_blank">
        <BaseButton
          type="gradient"
          icon="i-heroicons-arrow-trending-up"
          :text="$t('general.quotaExceededModal.action.view')"
        />
      </NuxtLink>
    </template>
  </BaseModal>
</template>

<style scoped></style>
