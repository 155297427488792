import { defineNuxtPlugin } from '#app'
import posthog from 'posthog-js'

export default defineNuxtPlugin(nuxtApp => {
    const {
        public: {
            posthog: {
                publicKey: posthogPublicKey,
                host: posthogHost,
            }
        }
    } = useRuntimeConfig();

    const posthogClient = posthog.init(posthogPublicKey, {
        api_host: posthogHost || 'https://us.i.posthog.com',
        person_profiles: 'identified_only',
        capture_pageview: false, // we add manual pageview capturing below
        loaded: (posthog) => {
            if (import.meta.env.MODE === 'development') posthog.debug();
        }
    })

    // Make sure that pageviews are captured with each route change
    const router = useRouter();
    router.afterEach((to) => {
        nextTick(() => {
            posthog.capture('$pageview', {
                current_url: to.fullPath
            });
        });
    });

    return {
        provide: {
            posthog: () => posthogClient
        }
    }
})